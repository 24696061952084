@use '@angular/material' as mat;

// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-typography-config());`
@include mat.all-component-typographies();
@include mat.core();

@import 'custom-theme.scss';

@import 'variables.scss';

@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';
@import '~quill-emoji/dist/quill-emoji.css';

html,
body {
  height: 100%;
  margin: 0;
}

/*
  .storyhub-theme is applied to the body tag of the app component
  essentially wrapping the entire app with the theme
*/
.storyhub-theme {
  /* Import custom angular material theme colors */
  @include mat.all-component-themes($storyhub-theme);

  /* Global styles for all components */
  font-family: Roboto, Arial, sans-serif;
  margin: 0;
  background-color: $background-color;

  /* HTML tag styling */
  h1 {
    font-size: 36px !important;
    font-weight: bold;
    line-height: 42px;
    color: mat.get-color-from-palette($accent);
  }

  h2 {
    font-size: 24px;
    line-height: 28px;
    font-weight: normal;
    margin-block-start: 0.8em;
    margin-block-end: 0.5em;
    color: $heading-color;
  }

  h3 {
    font-size: 18px;
    line-height: 21px;
    font-weight: normal;
    color: $heading-color;
  }

  h4 {
    font-size: 16px;
    line-height: 19px;
    font-weight: 500;
    color: $heading-color;
  }

  h5 {
    font-size: 14px;
    line-height: 14px;
    font-weight: 500;
    color: $small-heading-color;
  }

  .expansion-header {
    font-size: 20px !important;
    font-weight: bold !important;
    line-height: 20px !important;
    padding-top: 21px;
    padding-bottom: 21px;
  }

  p {
    color: $body-text-color;
    font-size: 14px;
    line-height: 20px;
  }

  pre {
    color: $body-text-color;
    font-size: 14px;
    line-height: 20px;
    font-family: Roboto, Arial, sans-serif;
  }

  .draft-text {
    color: $hint-text-color;
  }
  
  
  /* Material UI Components*/

  /* Mat Hint Text */
  .mat-mdc-form-field-hint {
    color: $icon-color;
    font-size: 14px;
  }

  /* Primary Button */
  .mat-mdc-button.mat-primary {
    background-color: $primary-button-background-color;
    height: 40px;
    text-transform: uppercase;

    &:hover:not([disabled]) {
      background-color: $primary-button-hover-color;
    }
  }

  /* Secondary Button */
  .mat-mdc-button {
    color: $heading-color;
    background-color: white;
    height: 40px;
    line-height: 40px;
    text-transform: uppercase;
    margin-left: 5px;
    margin-right: 5px;

    &:hover {
      background-color: $secondary-button-hover-color;
    }
  }

  /* Super Button*/
  .mat-mdc-unelevated-button {
    background-color: mat.get-color-from-palette($primary);
    color: white;
    height: 40px;
    text-transform: uppercase;

    &:hover:not([disabled]) {
      background-color: mat.get-color-from-palette($primary, 900);
    }
  }

  /* Revisions Button */
  .mat-mdc-button.revisions {
    color: $heading-color;
    background-color: white;
    text-transform: none;

    &:hover {
      color: mat.get-color-from-palette($primary);
      background-color: $primary-button-background-color;

      .mat-icon {
        color: mat.get-color-from-palette($primary);
        background-color: $primary-button-background-color;
      }
    }
  }

  /* Span as link */
  .link {
    cursor: pointer;
    color: mat.get-color-from-palette($primary);
    text-decoration: underline;

    &:active {
      color: red;
    }
  }

  /* Mat Spinner */
  .mat-mdc-progress-spinner {
    margin: 0 auto;
  }

  /* Mat SnackBar*/
  .mat-mdc-snack-bar-container.information {
    background-color: mat.get-color-from-palette($primary);
    color: white;

    .mat-mdc-button {
      background-color: mat.get-color-from-palette($primary);
      color: white;
    }
  }

  .mat-mdc-snack-bar-container.error {
    background-color: mat.get-color-from-palette($warn);
    color: white;

    .mat-mdc-button {
      background-color: mat.get-color-from-palette($warn);
      color: white;
    }
  }

  /* Information Button */
  .mat-mdc-mini-fab.mat-primary {
    font-size: 22px;
    font-weight: bold;
    background-color: $info-button-background-color;
    color: mat.get-color-from-palette($primary);
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.16), 0 2px 5px 0 rgba(0, 0, 0, 0.26);

    span > svg > g > g {
      fill: mat.get-color-from-palette($primary);
    }

    &.inactive {
      span > svg > g > g {
        fill: white;
      }

      background-color: mat.get-color-from-palette($primary);
      color: white;
    }
  }

  /* Mat Chips */
  .mat-mdc-chip.mat-mdc-standard-chip {
    background-color: $chip-background-color;
    color: $heading-color;
    height: auto;

    &:active {
      background-color: $active-chip-background-color;
    }
  }

  .mat-mdc-chip.matching {
    background-color: mat.get-color-from-palette($primary);
    color: white;
  }

  .display-item-chip.mat-mdc-chip.mat-mdc-standard-chip {
    background-color: $chip-background-color;
    color: $heading-color;
    min-height: 26px;
    font-size: 9px;
    font-weight: 500;
    line-height: 11px;
    padding: 5px 10px;
    margin: 2px;
    cursor: pointer;
    opacity: 1;
  }

  .mat-divider {
    color: $divider-color;
  }

  /* Mat Avatar */
  .mat-mdc-card-avatar {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    font-size: 16px;
    color: $heading-color;
    line-height: 48px;
    text-align: center;
    object-fit: cover;
    background-color: $avatar-background;

    &.primary {
      background-color: mat.get-color-from-palette($primary);
      color: white;
    }

    &.accent {
      background-color: mat.get-color-from-palette($accent);
      color: white;
    }

    &.pink {
      background-color: $avatar-pink;
      color: white;
    }
  }

  /* Mat Icons*/
  .mat-icon {
    color: $icon-color;
  }

  i.material-icons {
    color: $icon-color;
  }

  /* Mat Tooltip */
  .mat-mdc-tooltip {
    font-size: 0.8em;
  }
  
  /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
  .mat-checkbox-layout {
    white-space: normal;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
  .mat-checkbox-inner-container {
    margin-top: 4px;
  }

  /* Mat Card */
  .mat-mdc-card {
    border-radius: 1px;
    @include mat.elevation(2);

    &:hover {
      @include mat.elevation(6);
    }
  }

  /* Mat Expansion Panel */
  .mat-expansion-panel {
    border-radius: 1px;
  }

  /* Mat Toolbar*/

  .mat-toolbar {
    background-color: white !important;
  }

  .mat-mdc-header-cell {
    font-weight: bold;
    font-size: 13px;
    color: $heading-color;
  }

  .mat-mdc-cell {
    color: $heading-color;
  }

  mat-cell,
  mat-footer-cell,
  mat-header-cell {
    &:not(:last-of-type) {
      padding-right: 12px;
    }
  }

  .fill-remaining-space {
    flex: 1 1 auto;
  }

  .mat-mdc-table {
    font-size: 13px;

    .mat-mdc-cell {
      font-size: 13px;
    }

    .mat-mdc-row {
      cursor: pointer;
    }

    .mat-mdc-row:hover {
      background-color: lightgray;
    }
  }
  /* TODO(mdc-migration): The following rule targets internal classes of tooltip that may no longer apply for the MDC version.*/
  mat-tooltip-component > .mat-mdc-tooltip {
    white-space: pre;
    max-width: max-content;
  }
}

.ql-editor {
  font-family: Roboto, Arial, sans-serif;
}

.ql-tooltip {
  z-index: 9;
}

.storyhub-theme {
  .mat-mdc-dialog-container {
    overflow: visible;
  }
}

.storyhub-theme .ql-editor,
.storyPart {
  word-break: break-word;

  h1 {
    font-size: 16px;
    line-height: 19px;
    font-weight: 500;
    color: $heading-color;
    margin-block-start: 0.5em;
    margin-block-end: 0.5em;
  }
  h2 {
    font-size: 14px;
    line-height: 14px;
    font-weight: 500;
    color: $small-heading-color;
    margin-block-start: 0.4em;
    margin-block-end: 0.4em;
  }
}

.section-title {
  font-size: 30px !important;
  line-height: 35px !important;
  margin-bottom: 50px !important;
}

.sub-heading {
  margin-bottom: 15px !important;
}

.section-title {
  font-size: 30px !important;
  line-height: 35px !important;
  margin-bottom: 50px !important;
}

.sub-heading {
  margin-bottom: 15px !important;
}

input[type=search]::-ms-clear { display: none; width : 0; height: 0; }
input[type=search]::-ms-reveal { display: none; width : 0; height: 0; }
/* clears the ‘X’ from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration { display: none; }