/* Variables */
$background-color: #f5f5f5;
$heading-color: #414141;
$small-heading-color: #595959;
$body-text-color: #414141;
$hint-text-color: rgba(120, 120, 120, 0.51);
$primary-button-background-color: #f4f3f7;
$primary-button-hover-color: #e1dbf2;
$secondary-button-hover-color: #f1f0f0;
$info-button-background-color: #e2e2e2;
$chip-background-color: #e4e4e4;
$active-chip-background-color: #e0e0e0;
$icon-color: #787878;
$avatar-background: #dcdeee;
$avatar-pink: #ff2896;
$divider-color: #d8d8d8;
$warning-color: rgb(255, 0, 0);
